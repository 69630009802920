import { MouseEvent, useCallback, useRef } from 'react';
import styled from 'styled-components';

import { Box } from 'components/Box';
import { Button } from 'components/Button/Button';
import { closeDropdownFromElementWithin } from 'components/Dropdown/closeDropdownFromElementWithin';
import { StyledNativeLinkWithRef } from 'components/Link/StyledNativeLinkWithRef';
import { LocationInput } from 'components/LocationInput/LocationInput';
import { UncontrolledSearchQueryInputContainer } from 'containers/UncontrolledSearchQueryInputContainer';
import { UncontrolledListingTypeSelect } from 'modules/listing/components/ListingTypeSelect/UncontrolledListingTypeSelect';
import { searchTypes } from 'modules/search/constants/searchTypes';
import { usePopularSearches } from 'modules/search/hooks/usePopularSearches';
import { searchGetJobsRoutePath } from 'modules/search/routing/helpers/searchGetJobsRoutePath';
import { SearchLocation } from 'modules/search/types/SearchLocation';
import { SearchType } from 'modules/search/types/SearchType';
import { useUserEnvironment } from 'store/hooks/useUserEnvironment';
import { colors, cssBreakpoints } from 'theme/theme';
import { scrollToTop } from 'utils/scroll';

const SearchInputsForm = styled.form`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  gap: 8px;
  margin: 0 auto;
  padding: 0;
  font-size: 18px;

  @media all and (min-width: ${cssBreakpoints.smUp}) {
    max-width: 400px;
  }

  @media all and (min-width: ${cssBreakpoints.mdUp}) {
    max-width: none;
  }
`;

type Props = {
  initialQuery: string;
  defaultSearchType: SearchType;
  onSearch: (data: {
    query: string;
    listingType: SearchType;
    triggeredByRemoteLocationChange: boolean;
  }) => void;
  // Location
  searchLocation: SearchLocation | undefined;
  onChangeSearchLocation: (
    searchLocation: SearchLocation | undefined,
  ) => boolean;
  // Login/Signup
  redirectParam: string;
  showLogin: boolean;
  clickLogin: (event: MouseEvent<HTMLAnchorElement>) => void;
  clickSignup: (event: MouseEvent<HTMLAnchorElement>) => void;
};

export function SearchHeroInputs({
  initialQuery,
  defaultSearchType,
  onSearch,
  searchLocation,
  onChangeSearchLocation,
  redirectParam,
  showLogin,
  clickLogin,
  clickSignup,
}: Props) {
  const { isEnvironmentLoaded } = useUserEnvironment();

  const {
    popularSearches,
    listingTypeForPopularSearches,
    setListingTypeForPopularSearches,
  } = usePopularSearches(defaultSearchType);

  const formRef = useRef<HTMLFormElement>(null);
  const submit = useCallback(
    (options: { triggeredByRemoteLocationChange: boolean }) => {
      const form = formRef.current;
      if (!form) return;

      const formData = new FormData(form);
      onSearch({
        query: formData.get('q') as string,
        listingType: formData.get('listing-type') as SearchType,
        triggeredByRemoteLocationChange:
          options.triggeredByRemoteLocationChange,
      });

      closeDropdownFromElementWithin(form);
    },
    [onSearch],
  );

  return (
    <SearchInputsForm
      ref={formRef}
      method="GET"
      action={searchGetJobsRoutePath({ lang: CURRENT_LOCALE })}
      onSubmit={(event) => {
        event.preventDefault();
        submit({ triggeredByRemoteLocationChange: false });
      }}
      data-qa-id="search-inputs"
    >
      <Box component="label" display="block" flex="1">
        <Box display="none">{getText('Select listing type')}</Box>
        <UncontrolledListingTypeSelect
          name="listing-type"
          defaultValue={defaultSearchType}
          searchTypes={searchTypes}
          variant="outline-blue"
          onChange={(newListingType) =>
            setListingTypeForPopularSearches(newListingType)
          }
        />
      </Box>

      <Box height="100%" width="100%" flex={[null, null, null, '2']}>
        <UncontrolledSearchQueryInputContainer
          placeholder={getText('Search by keyword, skill, or interest')}
          initialQuery={initialQuery}
          presentation="light-border"
          id="search-query"
          qaId="search-input"
          popularSearches={popularSearches}
          selectedType={listingTypeForPopularSearches}
        />
      </Box>

      <div style={{ flex: 1 }}>
        <LocationInput
          affixVariant="transparent"
          styleVariant="light-border"
          hasRemoteOptions
          showLocationIcon
          showClearButton
          placeholder={getText('Everywhere')}
          locationText={searchLocation?.text}
          onChange={(newLocation) => {
            // Avoid submitting twice - the LocationInput has a bug that triggers the onChange twice
            if (!onChangeSearchLocation(newLocation)) return;

            // Submit when location is selected, but not when it's cleared
            if (newLocation) {
              submit({
                triggeredByRemoteLocationChange: Boolean(newLocation.isRemote),
              });
            }
          }}
        />
      </div>

      <Box
        flex="0 0 auto"
        height="100%"
        display={['block', null, null, 'flex']}
        mt="16px"
      >
        <Button
          type="submit"
          variant="primary"
          icon={{ type: 'normalized', name: 'search' }}
          data-qa-id="search-button"
          data-ready={isEnvironmentLoaded}
          onClick={scrollToTop}
          size="large"
          fullWidth
        >
          {getText('Search')}
        </Button>

        {redirectParam && showLogin && (
          <Box
            flex="1 1 auto"
            width="100%"
            display="flex"
            alignItems="center"
            justifyContent="center"
            mt={[24, null, null, 0]}
          >
            <StyledNativeLinkWithRef
              href={`/login?${redirectParam}`}
              data-qa-id="search-hero-login-link"
              onClick={clickLogin}
            >
              {getText('Log In')}
            </StyledNativeLinkWithRef>

            <Box mx="8px" color={colors.elementGrey}>
              |
            </Box>

            <StyledNativeLinkWithRef
              href={`/signup?${redirectParam}`}
              data-qa-id="search-hero-signup-link"
              onClick={clickSignup}
            >
              {getText('Sign up')}
            </StyledNativeLinkWithRef>
          </Box>
        )}
      </Box>
    </SearchInputsForm>
  );
}
