import queryString from 'query-string';
import { MouseEvent, useCallback } from 'react';
import { useStore } from 'react-redux';
import { useLocation } from 'react-router-dom';

import { useDeferredNavigate } from 'hooks/useDeferredNavigate';
import { getParsedSearchQuery } from 'modules/search/helpers/getParsedSearchQuery';
import { SearchPageLocationState } from 'modules/search/pages/Search/types/SearchPageLocationState';
import { searchGetRoutePathSwitch } from 'modules/search/routing/helpers/searchGetRoutePathSwitch';
import { SearchLocation } from 'modules/search/types/SearchLocation';
import { SearchType } from 'modules/search/types/SearchType';
import { MainStoreState } from 'store/MainStoreState';
import { useMainDispatch } from 'store/hooks/useMainDispatch';
import { useMainSelector } from 'store/hooks/useMainSelector';
import { useUserEnvironment } from 'store/hooks/useUserEnvironment';
import { updateSearchLocation } from 'store/mainSearch/mainSearch.actions';
import { trackClicked } from 'utils/analytics/track/trackClicked';
import { EMPTY_SEARCH_LOCATION } from 'utils/constants/general/emptySearchLocation';
import { deferAndStartRouteLoadingAnimation } from 'utils/ui/routeLoadingAnimation';
import { showModal } from 'zustand-stores/modalStore';

type Args = {
  searchFrom: string | undefined;
};

export function useSearchHero({ searchFrom }: Args) {
  const dispatch = useMainDispatch();
  const getState = useStore().getState as () => MainStoreState;

  const location = useLocation();
  const navigate = useDeferredNavigate();

  const { user } = useUserEnvironment();

  const searchLocation = useMainSelector(
    (state) => state.mainSearch.searchLocation,
  );

  const initialQuery = '';

  const changeSearchLocation = (
    newSearchLocation: SearchLocation | undefined,
  ): boolean => {
    if (newSearchLocation) {
      const state = getState();

      // The LocationInput triggers the change event twice except for the `Remote` option
      if (
        newSearchLocation.text &&
        state.mainSearch.searchLocation?.text === newSearchLocation.text
      )
        return false;

      deferAndStartRouteLoadingAnimation(() => {
        dispatch(updateSearchLocation(newSearchLocation, true));
      });
    } else {
      dispatch(updateSearchLocation(EMPTY_SEARCH_LOCATION, true));
    }

    return true;
  };

  const search = useCallback(
    (data: {
      query: string;
      listingType: SearchType;
      triggeredByRemoteLocationChange: boolean;
    }) => {
      const { parsedQuery, locationType, remoteKeywords } =
        getParsedSearchQuery({ query: data.query });

      const path = searchGetRoutePathSwitch({
        lang: CURRENT_LOCALE,
        q: parsedQuery,
        filtersByName: {
          type: data.listingType,
          locationType: data.triggeredByRemoteLocationChange
            ? 'REMOTE'
            : locationType,
        },
      });

      navigate(path, {
        state: { searchFrom, remoteKeywords } satisfies SearchPageLocationState,
      });
    },
    [navigate, searchFrom],
  );

  const redirectTo = location ? `${location.pathname}${location.search}` : '';
  const redirectParam = queryString.stringify({ to: redirectTo });

  const clickLogin = (event: MouseEvent<HTMLAnchorElement>) => {
    event.preventDefault();

    trackClicked('Log In Search Hero');
    showModal('LOGIN', { redirectTo });
  };

  const clickSignup = (event: MouseEvent<HTMLAnchorElement>) => {
    event.preventDefault();

    trackClicked('Sign Up Search Hero');
    showModal('SIGNUP', { redirectTo });
  };

  return {
    initialQuery,
    redirectParam,
    search,
    searchLocation,
    changeSearchLocation,
    clickLogin,
    clickSignup,
    showLogin: !user,
  };
}
